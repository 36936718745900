import React from "react";

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";

import { LiveTv, Pause, PauseCircle, StopCircle } from "@mui/icons-material";

export default function PageAppBar({
  stopStreaming,
  startStreaming,
  isStreaming,
  endStream,
}) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
      // sx={{ mb: 2 }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="text"
          size="small"
          onClick={isStreaming ? stopStreaming : startStreaming}
          color={isStreaming ? "info" : "secondary"}
          // startIcon={isStreaming ? <Pause /> : <LiveTv />}
        >
          {isStreaming ? "Pause" : "Resume"}
        </Button>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <LiveTv color={isStreaming ? "secondary" : "info"} />

          <Typography variant="h6" sx={{ mt: 0.3 }}>
            Notebook Live
          </Typography>
        </Box>
        {/* <IconButton onClick={endStream}>
          <StopCircle style={{ fontSize: "2.0rem" }} color="error" />
        </IconButton> */}
        <Button
          variant="text"
          size="small"
          onClick={endStream}
          color="error"
          startIcon={<StopCircle />}
        >
          End
        </Button>
      </Toolbar>
    </AppBar>
  );
}
