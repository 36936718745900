import moment from "moment";

import AppIcon from "../assets/app-icon.svg";

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
};

export const setCurrentUser = (userData) => {
  localStorage.setItem("currentUser", JSON.stringify(userData));
};

export const getCurrentUser = () => {
  try {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return currentUser;
  } catch (error) {
    console.log("Error getting current user", error);
    localStorage.removeItem("currentUser");
    window.location.reload();
  }
};

export const getArtistKey = () => {
  return localStorage.getItem("artistKey");
};

export const setArtistKey = (key) => {
  localStorage.setItem("artistKey", key);
};

export const clearArtistKey = () => {
  console.log("Clearing artist key");
  localStorage.removeItem("artistKey");
};

export const handleLogout = () => {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("artistKey");
  localStorage.removeItem("token");
  window.location.href = "/";
};

export const getUIStage = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("localhost")) {
    return "dev";
  } else if (hostname.includes("dev")) {
    return "dev";
  } else if (hostname.includes("192.")) {
    return "dev";
  } else if (hostname.includes("staging")) {
    return "staging";
  } else {
    return "prod";
  }
};

export const getApiBase = () => {
  if (window.location.hostname.includes("localhost")) {
    return "http://localhost:8081";
  } else {
    return `https://api-dot-notebook-fm-${getUIStage()}.uc.r.appspot.com`;
  }
};

export const getPageTitle = () => {
  let title = "Notebook";
  if (getUIStage() !== "prod") {
    title = `[${getUIStage()}] ${title}`;
  }

  if (window.location.hostname.includes("localhost")) {
    title = `[localhost] ${title}`;
  }
  return title;
};

export function formatSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const paddedSeconds = remainingSeconds.toString().padStart(2, "0");
  return `${minutes}:${paddedSeconds}`;
}

export function getContentArtwork(content) {
  if (content) {
    return content.meta.cover || content.owner.meta.artwork || AppIcon;
  }
  return null;
}

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1s",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY",
  },
});

export const fromNow = (timestamp) => {
  return moment.unix(timestamp).fromNow();
};
