import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, AppBar, Toolbar, IconButton } from "@mui/material";



import FeedCard from "./FeedCard";

import { API_BASE, artistKey, currentUser } from "../../App";
import LoadingCard from "./LoadingCard";

import BottomTabs from "../../components/BottomTabs";
import MainAppBar from "../../components/MainAppBar";
import { ArrowBackIos } from "@mui/icons-material";
import ArtistLogo from "@/fragments/ArtistLogo";

export default function Media() {
  
  const [contentKey, setContentKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [artistContent, setArtistContent] = useState(null);

  const [didScroll, setDidScroll] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    const fetchArtistContent = async () => {
      const endpoint = API_BASE + "/media";
      const response = await axios.get(endpoint, {
        params: {
          user_key: currentUser.key,
          artist_key: artistKey,
        },
      });
      setArtistContent(response.data.content);
      setLoading(false);
    };
    fetchArtistContent();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setContentKey(params.get("contentKey"));
  }, []);

  const scrollToContent = async () => {
    if (!contentRef.current) return;
    contentRef.current.scrollIntoView();
    window.scrollBy(0, -60);
    await new Promise((r) => setTimeout(r, 700));
    setDidScroll(true);
  };

  useEffect(() => {
    scrollToContent();
  }, [loading, artistContent]);

  return (
    <Box>
      {/* <MainAppBar /> */}
      <PageAppBar />

      {(loading || (!didScroll && contentKey)) && (
        <Box
          sx={{
            position: "fixed",
            top: 65,
            background: "rgba(19,19,19,1)",
            width: "100%",
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "600px",


          }}
        >
          <LoadingCard />
          <LoadingCard />
        </Box>
      )}

      <Box id="main-feed">
        <Box style={{ marginBottom: "100px" }}>
          {artistContent?.map((content, index) => (
            <Box
              key={content.key}
              ref={content.key === contentKey ? contentRef : null}
            >
              <FeedCard content={content} didScroll={didScroll} />
            </Box>
          ))}
        </Box>
        {!loading && artistContent.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              mt: 4,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "text.secondary",
              }}
            >
              No media found
            </Box>
          </Box>
        )}
      </Box>
      {/* <BottomTabs /> */}
    </Box>
  );
}

function PageAppBar() {
  const navigate = useNavigate();

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{ background: "rgba(19,19,19,.9)" }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
        <IconButton onClick={() => navigate(-1)} >
          <ArrowBackIos />
        </IconButton>
        </Box>
        
        {/* <AppLogo /> */}
        <ArtistLogo />
        <Box style={{ width: "40px" }} />
      </Toolbar>
    </AppBar>
  );
}