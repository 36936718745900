import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import PageAppBar from "./PageAppBar";
import LiveComments from "@/components/LiveComments";
import { API_BASE, currentUser } from "@/App";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";

export default function Broadcast() {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  // const uri = "rtmp://34.28.98.13/live/ed9f3f3d-a7f5-4a49-918e-4a49fe2ef4b6";
  const [uri, setUri] = useState(null);
  const [loading, setLoading] = useState(false);

  const [compact, setCompact] = useState(false);

  const startBroadcast = async () => {
    const endpoint = `${API_BASE}/start_broadcast`;
    const response = await axios.post(endpoint, {
      artist_key: currentUser.key,
    });
    console.log("Broadcast URI:", response.data.uri);
    setUri(response.data.uri);
  };

  useEffect(() => {
    startBroadcast();
  }, []);

  useEffect(() => {
    if (!uri) return;

    const WS_BASE = "wss://ws-dot-notebook-fm-staging.uc.r.appspot.com";
    // const WS_BASE = `ws://localhost:8080`;
    const socket = new WebSocket(`${WS_BASE}?uri=${encodeURIComponent(uri)}`);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log("WebSocket connected");
      setTimeout(() => startStreaming(), 2000);
    };

    socket.onclose = () => {
      console.log("WebSocket disconnected");
      setIsStreaming(false);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setError("WebSocket connection failed");
    };

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    };
  }, [uri]);

  const startStreaming = useCallback(async () => {
    console.log("Starting stream...");
    try {
      if (typeof MediaRecorder === "undefined" || !navigator.mediaDevices) {
        throw new Error(
          "MediaRecorder API or media devices are not supported in this browser."
        );
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1080 },
          height: { ideal: 1080 },
          facingMode: "user",
        },
        audio: true,
      });

      videoRef.current.srcObject = stream;

      const mimeTypes = [
        'video/mp4;codecs="avc1.42E01E, mp4a.40.2"',
        "video/webm;codecs=vp8,opus",
        "video/webm;codecs=vp8",
        "video/webm",
      ];

      const mimeType = mimeTypes.find((type) =>
        MediaRecorder.isTypeSupported(type)
      );

      if (!mimeType) {
        throw new Error("No suitable MIME type found for MediaRecorder.");
      }

      console.log("Using MIME type:", mimeType);

      const mediaRecorder = new MediaRecorder(stream, { mimeType });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0 && socketRef.current) {
          try {
            if (socketRef.current.readyState === WebSocket.OPEN) {
              socketRef.current.send(event.data);
            } else {
              console.warn("WebSocket is closed, data not sent.");
            }
          } catch (err) {
            console.error("Error sending video-data:", err);
          }
        }
      };

      mediaRecorder.start(1000); // Adjust this value if needed
      mediaRecorderRef.current = mediaRecorder;
      setIsStreaming(true);
    } catch (err) {
      console.error("Error starting stream:", err);
      setError(err.message);
    }
  }, []);

  const stopStreaming = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (videoRef.current?.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setIsStreaming(false);
  }, []);

  const endStream = useCallback(async () => {
    if (window.confirm("Are you sure you want to end live stream?")) {
      try {
        stopStreaming();
        setLoading(true);
        const endpoint = `${API_BASE}/end_broadcast`;
        await axios.post(endpoint, { artist_key: currentUser.key });
        window.location.href = "/";
      } catch (error) {
        console.error("Error ending stream:", error);
        setError("Failed to end stream");
      }
    }
  }, []);

  return (
    <Box>
      <PageAppBar
        stopStreaming={stopStreaming}
        startStreaming={startStreaming}
        endStream={endStream}
        isStreaming={isStreaming}
      />

      {/* making this ending state better */}
      {loading && <CircularProgress />}

      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box sx={{ px: 2 }}>
          {/* Video Container */}
          <Box
            sx={{
              position: compact ? "fixed" : "relative", // Fixed when compact
              top: compact ? 60 : "auto",
              right: compact ? 20 : "auto",
              zIndex: compact ? 1000 : "auto", // Keep it above other elements
              width: compact ? "33%" : "100%", // Adjust size in compact mode
              height: compact ? "auto" : "100%",
              borderRadius: "20px",
              overflow: "hidden",
              border: "1px solid #343332",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setCompact((prev) => !prev)}
          >
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              style={{
                width: "100%", // Make it responsive to the container's width
                height: "auto", // Keep height proportional
                objectFit: "cover", // Ensure video covers the container
              }}
            />
            {/* <IconButton onClick={() => setCompact((prev) => !prev)}>
              {compact ? <FullscreenExit /> : <Fullscreen />}
            </IconButton> */}
          </Box>

          {/* Toggle Compact Mode */}
        </Box>
      )}
      <LiveComments compact={compact} />
    </Box>
  );
}
