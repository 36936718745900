import React, { useEffect, useState } from "react";
import axios from "axios";

import { Box } from "@mui/material";

import CommentsContainer from "@/components/Comments/CommentsContainer";
import CaptionContainer from "@/fragments/CaptionContainer";

import { API_BASE, artistKey } from "@/App";

export default function LiveComments({ compact }) {
  const params = new URLSearchParams(window.location.search);
  const contentKey = params.get("key");

  const [content, setContent] = useState(null);

  // use async await
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(API_BASE + "/broadcast", {
          params: {
            artist_key: artistKey,
          },
        });
        setContent(response.data.content);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, [contentKey]);

  return (
    <Box sx={{ p: 2, my: 1 }}>
      {content && (
        <Box
          height={compact ? "70vh" : "40vh"}
          overflow="auto"
          sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <CaptionContainer content={content} />
          <CommentsContainer content={content} background="#121212" />
        </Box>
      )}
    </Box>
  );
}
