import React, { useEffect, useState } from "react";
import axios from "axios";
import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { artistKey, API_BASE, currentUser } from "@/App";
import { LiveTv } from "@mui/icons-material";

export default function LiveContainer() {
  const [status, setStatus] = useState("stopped");
  const [content, setContent] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const endpoint = `${API_BASE}/broadcast`;
        const response = await axios.get(endpoint, {
          params: {
            artist_key: artistKey,
          },
        });
        setStatus(response.data.status);
        setContent(response.data.content);
      } catch (error) {
        console.error("Failed to fetch broadcast status:", error);
      }
    };
    fetchStatus();
  }, []);

  const handleClick = () => {
    // navigate("/viewer"); // Navigate to /viewer page on click
    if (currentUser.role === "artist" && currentUser.key === artistKey) {
      navigate("/broadcast"); // Navigate to /viewer page on click
    } else {
      navigate("/viewer"); // Navigate to /viewer page on click
    }
  };

  return (
    <>
      {status != "stopped" && (
        <AppBar position="sticky" elevation={3} color="primary">
          <Toolbar>
            <Box
              onClick={handleClick}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <LiveTv style={{ marginRight: 8 }} color="secondary" />
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Broadcasting Live
              </Typography>
              <Button color="secondary">Join</Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
